import {
  CSS3,
  Express,
  HTML5,
  Javascript,
  Mongodb,
  Nextjs,
  Nextjs2,
  Nodejs,
  Python,
  Reactjs,
  admin,
  food,
  food1,
  port1,
  port2,
  port3,
  social,
} from "./assets";

export const projectStats = [
  {
    name: "Total Projects",
    value: "363+",
  },
  {
    name: "Web Application",
    value: "25+",
  },
  {
    name: "Desktop Application",
    value: "330+",
  },
  {
    name: "Clients worldwide",
    value: "150+",
  },
];

export const tools = [
  {
    name: "MongoDB",
    icon: Mongodb,
  },
  {
    name: "Express",
    icon: Express,
  },
  {
    name: "React",
    icon: Reactjs,
  },
  {
    name: "NodeJs",
    icon: Nodejs,
  },
  {
    name: "NextJs",
    icon: Nextjs2,
  },
];

export const skills = [
  {
    name: "ReactJs",
    value: 92,
    icon: Reactjs,
  },
  {
    name: "NodeJs",
    value: 90,
    icon: Nodejs,
  },
  {
    name: "NextJs",
    value: 88,
    icon: Nextjs,
  },
  {
    name: "MongoDB",
    value: 90,
    icon: Mongodb,
  },
  {
    name: "CSS3",
    value: 98,
    icon: CSS3,
  },
  {
    name: "HTML5",
    value: 88,
    icon: HTML5,
  },
  {
    name: "JavaScript",
    value: 95,
    icon: Javascript,
  },
  {
    name: "Python",
    value: 78,
    icon: Python,
  },
];

export const projects = [
  {
    id: 1,
    title: "Budject Dashboard",
    cat: "Web App",
    url: "",
    img: admin,
  },
  {
    id: 2,
    title: "Budget Income",
    cat: "Web App",
    url: "",
    img: port1,
  },
  {
    id: 3,
    title: "Bank Reconciliation",
    cat: "Web App",
    url: "",
    img: port2,
  },
  {
    id: 4,
    title: "Net Worth",
    cat: "Web App",
    url: "",
    img: port3,
  },
  {
    id: 5,
    title: "User Profile",
    cat: "Web App",
    url: "",
    img: food,
  },
  {
    id: 6,
    title: "Budget Categories",
    cat: "Web App",
    url: "",
    img: social,
  },
  {
    id: 7,
    title: "Login Page",
    cat: "Web App",
    url: "",
    img: food1,
  },
];

export const softskills = [
  "Time management",
  "Communication",
  "Adaptability",
  "Collaboration",
  "Resilience",
  "Creativity",
  "Critical thinking",
  "Accountability",
  "Problem solving",
  "Open-mindedness",
];
