import React from "react";
import { Profile } from "../assets";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlineAlternateEmail, MdWifiCalling2 } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

const About = () => {
  return (
    <div className='w-full flex flex-col lg:flex-row px-8 md:px-10 gap-10 lg:gap-20 lg:py-20 '>
      <div className='w-full md:h-[290px] lg:w-1/3 flex flex-col items-center border border-gray-500 dark:bg-transparent rounded-md'>
        <img
          src={Profile}
          className='h-[290px] p-1 rounded-md ease-in-out duration-300 hover:scale-125'
        />
      </div>

      <div className='w-full flex flex-col'>
        <p className='text-3xl font-bold text-black dark:text-white  '>
          About Me
        </p>
        <p className='text-lg text-black dark:text-gray-400 leading-10'>
        I'm a Full-Stack Business Solutions and Web Application Developer fueled by a passion for creating seamless digital experiences. 
        With a foundation built on JavaScript, Node.js, React.js, Next.js, and TypeScript, I thrive in dynamic environments where 
        learning and collaboration drive innovation. My journey began with dedicated hours in bootcamp settings, honing my skills 
        and mastering the intricacies of modern web development. Now, armed with a versatile skill set and an insatiable curiosity, 
        I'm eager to tackle new challenges and push the boundaries of what's possible in web development and design. Whether it's 
        crafting elegant UIs, optimizing backend functionality, or brainstorming innovative solutions, I'm committed to making a 
        lasting impact on user experiences. Let's connect and embark on a journey to shape the digital landscape together.
        </p>

        <div
          className='mt-5 2xl:mt-10 flex flex-wrap gap-5'
          data-aos='fade-up'
          data-aos-offset='200'
          data-aos-delay='50'
          data-aos-duration='1000'
          data-aos-easing='ease-in-out'
        >
          <p className='flex gap-3 items-center justify-center rounded-full shadow-lg py-2 px-4 bg-[#030a1c] text-white cursor-pointer'>
            <FaUserAlt size={14} /> Vusi Shongwe
          </p>
          <p className='flex gap-3 items-center justify-center rounded-full shadow-lg py-2 px-4 bg-[#030a1c] text-white cursor-pointer'>
            <MdOutlineAlternateEmail size={14} /> info@VusiShongwe.com
          </p>
          <a
            href='https://wa.me/+27 84 940 8440'
            className='flex gap-3 items-center justify-center rounded-full shadow-lg py-2 px-4 bg-[#030a1c] text-white cursor-pointer'
          >
            <BsWhatsapp size={14} /> +27 84 940 8440
          </a>
          {/* <p className='flex gap-3 items-center justify-center rounded-full shadow-lg py-2 px-4 bg-[#030a1c] text-white cursor-pointer'>
            <MdWifiCalling2 size={14} /> +27 84 940 8440
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default About;
